import { Component } from '@angular/core';

@Component({
  selector: 'home-footer',
  template: `
    <div class="home-footer p-tb-30 p-lr-40 text-light-yellow fade-in-anim">
      <svg class="icon" fill="currentColor">
        <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
      </svg>
      <div class="entities text-light-yellow bold-weight text-very-large">
        <a class="kawaa-entity display-block text-light-yellow" href="/">kawaa</a>
        <a class="display-block text-light-yellow" href="https://paris.kawaa.co">Paris Daumesnil</a>
        <a class="display-block text-light-yellow" href="https://lumiere.kawaa.co">Paris Lumière</a>
        <a class="display-block text-light-yellow" href="https://lucernaire.kawaa.co">Paris Lucernaire</a>
        <a class="display-block text-light-yellow" href="https://lille.kawaa.co">Lille Gambetta</a>
      </div>
      <div class="contact text-light-yellow bold-weight">
        <a class="text-current-color " target="_blank" rel="noopener" href="mailto:bonjour@kawaa.com">bonjour@kawaa.co</a>
      </div>
      <div class="menu-social">
        <a class="bold-weight display-block text-light-yellow" href="https://www.linkedin.com/company/kawaa/">LinkedIn</a>
      </div>
      <div class="mentions bold-weight">
        <div>kawaa est agréée entreprise solidaire depuis 2014</div>
        <div>En savoir plus <a class="text-current-color underline" href="/a-propos">ici</a></div>
        <div class="m-s-t">© kawaa 2023, tous droits réservés</div>
      </div>
    </div>
  `
})

export class HomeFooterComponent {}
