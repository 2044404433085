import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <div class="fade-in-anim">
      <div class="page-title home-page-title text-light-yellow p-lr-80">
        <h1>
          On a tant à partager.
        </h1>
      </div>
      <div class="main-image flex home-main-image">
        <img src="assets/images/home/shared-moment.jpeg" alt="people sharing a coffee time">
      </div>
      <div class="post-block">
        <div class="post bg-light-yellow text-brown">
          <div class="text">
            <p class="paragraph">
              Kawaa, ce sont des lieux uniques et pluriels qui favorisent et réunissent tout ce qui crée du lien et des croisements entre voisins, du matin jusqu'au soir.
            </p>
            <p class="paragraph">
              Un kawaa, c'est un café
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              des ateliers
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              une cuisine
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              des rencontres
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              un bureau
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              une maison
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              du partage de tout ce que tu veux !
            </p>
            <p class="paragraph">
              Kawaa, c'est déjà des lieux à Paris et à Lille, et bientôt il y en aura plein d'autres.
            </p>
          </div>
          <div class="borders">
            <div class="border-t-post-special-purple"></div>
            <div class="border-r-post-green"></div>
            <div class="border-b-post-special-yellow"></div>
            <div class="border-l-post-orange"></div>
          </div>
        </div>
        <div class="post-image bg-light-yellow flex">
          <img src="assets/images/home/coffee-environment.png" alt="people at a coffee place">
        </div>
        <div class="link-banner bg-light-yellow flex">
          <a class="left-link half-width" href="https://lumiere.kawaa.co">
            <div class="text space-between p-lr-40 p-tb-30 bg-link-banner-yellow text-dark-yellow">
              <p class="title paragraph">Venez nous voir<br>à Lumière</p>
              <p class="link paragraph text-large">paris.kawaa.co</p>
            </div>
            <div class="image">
              <img class="" src="assets/images/home/kawaa-paris.png" alt="a coffee terrace">
            </div>
          </a>
          <a class="right-link half-width" href="https://lille.kawaa.co">
            <div class="text space-between p-lr-40 p-tb-30 bg-link-banner-purple text-dark-purple">
              <p class="title paragraph">Le kawaa de<br>Lille ouvre en 2025</p>
              <p class="link paragraph text-large">Plus d'information</p>
            </div>
            <div class="image">
              <img class="" src="assets/images/home/kawaa-lille.png" alt="a cute back street">
            </div>
          </a>
        </div>
      </div>
    </div>
  `
})

export class HomepageComponent implements OnInit {

  ngOnInit() {}
}
